import React from "react"

const back = () => {
    return (
        <a href="/" className="mt-8 text-xl text-orange-500 hover:text-orange-600 inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            <span>戻る</span>
        </a>
    )
}

export default back
