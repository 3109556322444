import React from "react"
import Meta from "../components/meta"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Title from "../components/title"
import Back from "../components/back"

export default function Home() {
  return (
    <>
      <Meta title="私たちが大切にしていること" description="当社は 2017 年に設立されたばかりの小さな会社であり大きなことはできないかもしれませんが、私は情報技術の持つ可能性を信じ、職人のようにひたむきに一歩一歩、皆様に必要とされる企業となれるよう進んでまいります。" />
      <div className="flex flex-col h-screen">
        <header>
          <Navbar hasContact={false} />
        </header>
        <main className="mt-14 reveal fade-bottom">
          <div className="container max-w-5xl mx-auto py-4 px-4">
            <Title title="私たちが大切にしていること" />

            <div className="bg-white rounded-lg mb-4 flex flex-wrap">
              <div className="w-full p-6">
                <h3 className="card-heading text-xl md:text-3xl font-bold leading-none">
                  誠実であること
                </h3>
                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  私たちは、引き受けた業務を完遂、約束したことを実行します。常に自らの公平性と客観性を検証し、実務者として誠実に正直な態度で行動します。
                </p>

                <h3 className="mt-4 md:mt-8 card-heading text-xl md:text-3xl font-bold leading-none">
                  努力を惜しまない
                </h3>
                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  私たちは、あらゆる技術によりお客様のご要望にお応えします。ただお客様のご要望にお応えするだけではなく、既存のパッケージでは満たすことのできないきめ細かな対応をご提供します。
                </p>

                <div className="md:text-lg mx-8">
                  <p className="mt-4 md:mt-8 text-gray-600 leading-9 text-center">
                    社名の一部であるクラフトには<br className="br-sp" />技術や技巧という意味があります。<br />
                    当社は 2017 年に<br className="br-sp" />設立されたばかりの<br className="br-sp" />小さな会社であり<br />大きなことはできないかも<br className="br-sp" />しれませんが、<br />
                    私たちは情報技術の持つ<br className="br-sp" />可能性を信じ、<br className="br-sp" />職人のように<br className="br-sp" />ひたむきに一歩一歩、<br />
                    皆様に<br className="br-sp" />必要とされる企業と<br className="br-sp" />なれるよう進んでまいります。
                  </p>

                  <p className="mt-4 md:mt-8 text-gray-600 leading-9 text-center">
                    皆様のご支援を<br className="br-sp" />何卒宜しくお願いいたします。
                  </p>
                </div>

                <div className="mt-8 text-center">
                  <a href="/develop" className="bg-orange-500 hover:bg-orange-700 text-white py-2 px-4 border border-orange-500 hover:border-transparent rounded inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                    </svg>
                    <span className="inline-block ml-2 font-medium">開発事例はコチラ</span>
                  </a>
                </div>

                <Back />
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}
