import React from "react"
import Helmet from "react-helmet"

const meta = (props) => {
    return (
        <Helmet>
            <html lang="ja"></html>
            <title>{props.title}</title>
            <meta name="keywords" content="京都,関西,システム開発,Webシステム,業務コンサルティング" />
            <meta name="description" content={props.description} />
            <meta property="og:title" content={props.title} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={props.description} />
            <meta property="og:url" content="http://www.bellcraft.co.jp/" />
            <meta property="og:image" content="3_White_logo_on_color1_1024.png" />
            <meta property="og:locale" content="ja_JP" />
            <meta property="og:site_name" content="株式会社ベルクラフト" />
        </Helmet>
    )
}

export default meta
